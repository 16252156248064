import {
    SET_CLIENT_ERROR,
    SET_RTC_CONNECTION_TIME,
    SET_RTC_DISCONNECTION_TIME,
    SET_ON_GO_BACK_TO_PUBLIC
} from '../constants/clientError/actionType'

export const setClientError = (payload) => ({
    type: SET_CLIENT_ERROR,
    payload
})

export const setRTCConnectionTime = (payload) => ({
    type: SET_RTC_CONNECTION_TIME,
    payload
})

export const setRTCDisconnectionTime = (payload) => ({
    type: SET_RTC_DISCONNECTION_TIME,
    payload
})

export const setOnGoBackToPublic = (payload) => ({
    type: SET_ON_GO_BACK_TO_PUBLIC,
    payload
})
