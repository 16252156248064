import React from 'react'

import Header from '../../components/Header'

import burgerMenu from '../../assets/icons/burger-menu.svg'

import './ChatRecord.scss'
// import classNames from 'classnames'
// import moment from 'moment'
// import readMessage from '../../assets/icons/read-message.svg'
// import { setIsReceivedMessage } from '../../store/actions/messages'

function ChatRecord() {
    return (
        <div className="chat-record">
            <Header
                menuButton={
                    <button className="header__profile-burger completed-lesson-header-burger">
                        <img src={burgerMenu} alt="burger" />
                    </button>
                }
                className="completed-lesson-header header"
            />
            <div className="chat-record__title">
                <h1>Чат занятия</h1>
            </div>
            <div className="chat-record__messages">
                {/*{messages &&*/}
                {/*    messages.map((message, i) => (*/}
                {/*        <div*/}
                {/*            key={i}*/}
                {/*            className={classNames(*/}
                {/*                'messenger__message',*/}
                {/*                message.userId === user.id*/}
                {/*                    ? 'messenger__message_outgoing'*/}
                {/*                    : 'messenger__message_incoming'*/}
                {/*            )}*/}
                {/*        >*/}
                {/*            {message.userId !== user.id && (*/}
                {/*                <>*/}
                {/*                    <p className="messenger__message-time">*/}
                {/*                        {moment(message.createdAt).format('HH:mm')}*/}
                {/*                    </p>*/}
                {/*                    <span className="messenger__message-teacher">*/}
                {/*                        {`${otherUser.role.name}: ${otherUser.attributes.firstName} ${otherUser.attributes.lastName}`}*/}
                {/*                    </span>*/}
                {/*                </>*/}
                {/*            )}*/}
                {/*            <div style={{ display: 'flex' }}>*/}
                {/*                {message.userId === user.id && (*/}
                {/*                    <img*/}
                {/*                        src={readMessage}*/}
                {/*                        alt="read"*/}
                {/*                        style={{ marginRight: '10px', alignSelf: 'flex-end' }}*/}
                {/*                    />*/}
                {/*                )}*/}
                {/*                <div className="messenger__message-text">*/}
                {/*                    <p onClick={() => dispatch(setIsReceivedMessage(true))}>*/}
                {/*                        {message.text}*/}
                {/*                    </p>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    ))}*/}
            </div>
        </div>
    )
}

export default ChatRecord
