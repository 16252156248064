import { UPDATE_USER } from '../actions/users'
import { SET_USER, SET_USERS } from '../constants/users/actionType'

const initialState = {
    users: [],
    user: {}
}
export const users = (state = initialState, action) => {
    switch (action.type) {
        case SET_USERS:
            return {
                ...state,
                users: action.payload
            }
        case SET_USER:
            return {
                ...state,
                user: action.payload
            }
        case UPDATE_USER:
            const user = action.payload
            return { ...state, users: state.users.map((u) => (u.id === user.id ? user : u)) }

        default:
            return state
    }
}
