import {
    SET_CHAT_CANVAS_RECORDER,
    SET_CHAT_ID,
    SET_CHAT_TIMINGS,
    SET_CHAT_VIDEO_RECORDER,
    SET_IS_ACTIVE_CHAT_MESSENGER,
    SET_IS_CHAT_COMPLETED,
    SET_IS_VISIBLE_CHAT_MENU
} from '../constants/chats/actionTypes'

export const setChatId = (payload) => ({
    type: SET_CHAT_ID,
    payload
})
export const setChatTimings = (payload) => ({
    type: SET_CHAT_TIMINGS,
    payload
})
export const setIsVisibleChatMenu = (payload) => ({
    type: SET_IS_VISIBLE_CHAT_MENU,
    payload
})
export const setIsChatCompleted = (payload) => ({
    type: SET_IS_CHAT_COMPLETED,
    payload
})

export const setIsActiveChatMessenger = (payload) => ({
    type: SET_IS_ACTIVE_CHAT_MESSENGER,
    payload
})
export const setChatVideoRecorder = (payload) => ({
    type: SET_CHAT_VIDEO_RECORDER,
    payload
})
export const setChatCanvasRecorder = (payload) => ({
    type: SET_CHAT_CANVAS_RECORDER,
    payload
})
