export const SET_CHAT_ID = 'SET_CHAT_ID'
export const SET_IS_CHAT_ACTIVE = 'SET_IS_CHAT_ACTIVE'
export const SET_CHAT_TIMINGS = 'SET_CHAT_TIMINGS'
export const SET_CHAT_TOKEN = 'SET_CHAT_TOKEN'
export const SET_CHAT_ORDER_ID = 'SET_CHAT_ORDER_ID'

export const SET_IS_VISIBLE_CHAT_MENU = 'SET_IS_VISIBLE_CHAT_MENU'
export const SET_IS_CHAT_COMPLETED = 'SET_IS_CHAT_COMPLETED'
export const SET_IS_ACTIVE_CHAT_MESSENGER = 'SET_IS_ACTIVE_CHAT_MESSENGER'
export const SET_CHAT_VIDEO = 'SET_CHAT_VIDEO'
export const SET_CHAT_VIDEO_RECORDER = 'SET_CHAT_VIDEO_RECORDER'
export const SET_CHAT_CANVAS_RECORDER = 'SET_CHAT_CANVAS_RECORDER'
