import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { setModal } from '../../store/actions/mediaDevice'

import { /* selectVideo, selectAudio, */ selectService } from '../../store/selectors/mediaDevice'

import classNames from 'classnames'

import microphoneIcon from '../../assets/icons/modal-microphone.svg'
import cameraIcon from '../../assets/icons/modal-camera.svg'
import activeStatusIcon from '../../assets/icons/modal-status-active.svg'
import inactiveStatusIcon from '../../assets/icons/modal-status-inactive.svg'
import lockIcon from '../../assets/icons/modal-lock.svg'
import settingsIcon from '../../assets/icons/modal-settings.svg'
import linkIcon from '../../assets/icons/modal-link.svg'
import microphoneSecondaryIcon from '../../assets/icons/modal-microphone-secondary.svg'
import cameraSecondaryIcon from '../../assets/icons/modal-camera-secondary.svg'
import arrowIcon from '../../assets/icons/modal-arrow-white.svg'
import lockSecondaryIcon from '../../assets/icons/modal-lock-secondary.svg'
import closeIcon from '../../assets/icons/modal-close.svg'
import closeGreenIcon from '../../assets/icons/modal-close-green.svg'
import lineIcon from '../../assets/icons/modal-line.svg'
import arrowGreenIcon from '../../assets/icons/modal-arrow-green.svg'
import confidentialityLockIcon from '../../assets/icons/modal-confidentiality-lock.svg'

import './ModalMediaResolution.scss'
import Button from '../UI/button'

const CHROME = 1
const YANDEX = 2
const FIREFOX = 3
const OPERA = 4
const EDGE = 5

const tabs = [
    { id: CHROME, name: 'Chrome' },
    { id: YANDEX, name: 'Yandex' },
    { id: FIREFOX, name: 'Firefox' },
    { id: OPERA, name: 'Opera' },
    { id: EDGE, name: 'Edge' }
]

function ModalMediaResolution() {
    const dispatch = useDispatch()
    // const video = useSelector(selectVideo)
    // const audio = useSelector(selectAudio)
    const service = useSelector(selectService)
    const [activeTab, setActiveTab] = useState(1)

    const [video, setVideo] = useState(false)
    const [audio, setAudio] = useState(false)

    const closeModal = () => {
        dispatch(setModal(null))
    }

    useEffect(() => {
        const userAgent = navigator.userAgent
        let browser = 'unkown'
        // Detect browser name
        browser = /ucbrowser/i.test(userAgent) ? 'UCBrowser' : browser
        browser = /edg/i.test(userAgent) ? 'Edge' : browser
        browser = /googlebot/i.test(userAgent) ? 'GoogleBot' : browser
        browser = /chromium/i.test(userAgent) ? 'Chromium' : browser
        browser =
            /firefox|fxios/i.test(userAgent) && !/seamonkey/i.test(userAgent) ? 'Firefox' : browser
        browser =
            /; msie|trident/i.test(userAgent) && !/ucbrowser/i.test(userAgent) ? 'IE' : browser
        browser =
            /chrome|crios/i.test(userAgent) &&
            !/opr|opera|chromium|edg|ucbrowser|googlebot/i.test(userAgent)
                ? 'Chrome'
                : browser
        browser =
            /safari/i.test(userAgent) &&
            !/chromium|edg|ucbrowser|chrome|crios|opr|opera|fxios|firefox/i.test(userAgent)
                ? 'Safari'
                : browser
        browser = /opr|opera/i.test(userAgent) ? 'Opera' : browser
        browser = userAgent.search(/YaBrowser/) > 0 ? 'Yandex' : browser

        console.log(`%cUser use browser: ${browser}`, 'color: burlywood')

        switch (browser) {
            case 'Chrome':
                return setActiveTab(CHROME)
            case 'Yandex':
                return setActiveTab(YANDEX)
            case 'Firefox':
                return setActiveTab(FIREFOX)
            case 'Opera':
                return setActiveTab(OPERA)
            case 'Edge':
                return setActiveTab(EDGE)
            default:
                return setActiveTab(CHROME)
        }
    }, [])

    useEffect(() => {
        const setAudioPermissionStatus = async () => {
            try {
                const result = await navigator.mediaDevices.getUserMedia({
                    video: false,
                    audio: true
                })
                console.log('%cAudio permission: ', 'color: green', result.active)
                setAudio(result.active)
            } catch (error) {
                console.warn('%cError: No audio permission', 'color: #DC4C64', error)
            }
        }

        const setVideoPermissionStatus = async () => {
            try {
                const result = await navigator.mediaDevices.getUserMedia({
                    video: true,
                    audio: false
                })
                console.log('%cCamera permission: ', 'color: green', result.active)
                setVideo(result.active)
            } catch (error) {
                console.warn('%cError: No camera permission', 'color: #DC4C64', error)
            }
        }

        setAudioPermissionStatus()
        setVideoPermissionStatus()
    }, [])

    return (
        <div className="container">
            <div className="container__bg" onClick={closeModal} />

            <div className="container-modal">
                <div className="container-inner-container">
                    <div className="container-modal__close">
                        <div onClick={closeModal}>
                            <img src={closeGreenIcon} alt="lock" />
                            <span>закрыть</span>
                        </div>
                    </div>

                    <h3 className="container-modal__title">Проверка настроек устройства</h3>

                    <div className="container-modal__wrapper">
                        <p>
                            Для доступа к занятию, необходимо разрешить платформе использовать
                            микрофон и камеру Вашего устройства.
                        </p>

                        <div className="container-modal__icon">
                            <div className="container-modal__icon__wrapper">
                                <img src={microphoneIcon} alt="microphone" />
                                <img
                                    className="container-modal__icon__status"
                                    src={audio ? activeStatusIcon : inactiveStatusIcon}
                                    alt="status"
                                />
                            </div>
                            <span>Микрофон</span>
                        </div>

                        <div className="container-modal__icon">
                            <div className="container-modal__icon__wrapper">
                                <img src={cameraIcon} alt="camera" />
                                <img
                                    className="container-modal__icon__status"
                                    src={video ? activeStatusIcon : inactiveStatusIcon}
                                    alt="status"
                                />
                            </div>
                            <span>Камера</span>
                        </div>
                    </div>

                    <div className="container-modal__tabs">
                        {tabs?.map((tab) => {
                            return (
                                <div
                                    key={tab.id}
                                    className={classNames(
                                        'container-modal__tabs__item',
                                        activeTab === tab.id ? 'active' : ''
                                    )}
                                    onClick={() => setActiveTab(tab.id)}
                                >
                                    {tab.name}
                                </div>
                            )
                        })}
                    </div>

                    <div className="container-modal__content">
                        {activeTab === CHROME && (
                            <div className="container-modal__chrome">
                                <div className="container-modal__chrome__wrapper">
                                    <p className="container-modal__chrome__text">
                                        Нажмите на значок замка <br /> слева от адресной строки.
                                    </p>

                                    <div className="container-modal__chrome__input">
                                        <img src={lockIcon} alt="lock" />
                                        <span className="container-modal__chrome__text secondary">
                                            classroom.edu-cator.ru
                                        </span>
                                    </div>
                                </div>

                                <div className="container-modal__chrome__wrapper">
                                    <p className="container-modal__chrome__text">
                                        Выберите пункт "Настройки сайтов".
                                    </p>

                                    <div className="container-modal__chrome__settings">
                                        <img src={settingsIcon} alt="settings" />
                                        <span className="container-modal__chrome__text secondary">
                                            Настройки сайтов
                                        </span>
                                        <img src={linkIcon} alt="link" />
                                    </div>
                                </div>

                                <div className="container-modal__chrome__wrapper">
                                    <p className="container-modal__chrome__text">
                                        В разделе “Безопасность и <br /> конфиденциальность”
                                        найдите пункты <br /> "Камера" и "Микрофон" и настройте{' '}
                                        <br /> разрешения.
                                    </p>

                                    <div className="container-modal__chrome__permissions">
                                        <div className="container-modal__chrome__permissions__flex">
                                            <img src={cameraSecondaryIcon} alt="camera" />
                                            <span className="container-modal__chrome__text secondary">
                                                Камера
                                            </span>
                                            <div className="container-modal__chrome__permissions__wrapper">
                                                <span>Разрешено</span>
                                                <img src={arrowIcon} alt="arrow" />
                                            </div>
                                        </div>

                                        <div className="container-modal__chrome__permissions__line" />

                                        <div className="container-modal__chrome__permissions__flex">
                                            <img
                                                className="container-modal__chrome__permissions__microphone"
                                                src={microphoneSecondaryIcon}
                                                alt="microphone"
                                            />
                                            <span className="container-modal__chrome__text secondary">
                                                Микрофон
                                            </span>
                                            <div className="container-modal__chrome__permissions__wrapper">
                                                <span>Разрешено</span>
                                                <img src={arrowIcon} alt="arrow" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="container-modal__chrome__refresh">
                                    <p className="container-modal__chrome__text">
                                        Обновите страницу платформы.
                                    </p>
                                </div>
                            </div>
                        )}

                        {activeTab === YANDEX && (
                            <div className="container-modal__yandex">
                                <div className="container-modal__yandex__wrapper">
                                    <p className="container-modal__yandex__text margin">
                                        Нажмите на значок «Камера» <br /> в правой части адресной
                                        строки
                                    </p>

                                    <div className="container-modal__yandex__input">
                                        <span className="container-modal__yandex__text secondary">
                                            classroom.edu-cator.ru
                                        </span>
                                        <div className="container-modal__yandex__input__icons">
                                            <img
                                                className="container-modal__yandex__input__camera"
                                                src={cameraSecondaryIcon}
                                                alt="camera"
                                            />
                                            <img
                                                className="container-modal__yandex__input__microphone"
                                                src={microphoneSecondaryIcon}
                                                alt="microphone"
                                            />
                                            <img src={lockSecondaryIcon} alt="lock" />
                                        </div>
                                    </div>
                                </div>

                                <div className="container-modal__yandex__wrapper">
                                    <p className="container-modal__yandex__text margin">
                                        Найдите пункты «Использовать камеру» <br /> и «Использовать
                                        микрофон» и настройте <br /> разрешения
                                    </p>

                                    <div className="container-modal__yandex__permissions">
                                        <div className="container-modal__yandex__permissions__flex">
                                            <span className="container-modal__yandex__text secondary">
                                                Использовать камеру
                                            </span>
                                            <img
                                                className="container-modal__yandex__permissions__camera"
                                                src={cameraSecondaryIcon}
                                                alt="camera"
                                            />
                                        </div>

                                        <div className="container-modal__yandex__permissions__wrapper">
                                            <div className="container-modal__yandex__permissions__switcher">
                                                <div className="container-modal__yandex__permissions__switcher__ball" />
                                            </div>

                                            <span className="container-modal__yandex__permissions__text">
                                                Вкл.
                                            </span>
                                        </div>

                                        <span className="container-modal__yandex__permissions__text">
                                            Настроить
                                        </span>

                                        <div className="container-modal__yandex__permissions__line" />

                                        <div className="container-modal__yandex__permissions__flex">
                                            <span className="container-modal__yandex__text secondary">
                                                Использовать микрофон
                                            </span>
                                            <img
                                                className="container-modal__yandex__permissions__microphone"
                                                src={microphoneSecondaryIcon}
                                                alt="microphone"
                                            />
                                        </div>

                                        <div className="container-modal__yandex__permissions__wrapper">
                                            <div className="container-modal__yandex__permissions__switcher">
                                                <div className="container-modal__yandex__permissions__switcher__ball" />
                                            </div>

                                            <span className="container-modal__yandex__permissions__text">
                                                Вкл.
                                            </span>
                                        </div>

                                        <span className="container-modal__yandex__permissions__text">
                                            Настроить
                                        </span>
                                    </div>
                                </div>

                                <div className="container-modal__yandex__refresh">
                                    <p className="container-modal__yandex__text">
                                        Обновите страницу платформы.
                                    </p>
                                </div>
                            </div>
                        )}

                        {activeTab === FIREFOX && (
                            <div className="container-modal__firefox">
                                <div className="container-modal__firefox__wrapper">
                                    <p className="container-modal__firefox__text">
                                        Нажмите на значок «Камера» <br /> слева от адресной строки.
                                    </p>

                                    <div className="container-modal__firefox__input">
                                        <img
                                            className="container-modal__firefox__input__camera"
                                            src={cameraSecondaryIcon}
                                            alt="camera"
                                        />
                                        <img src={lockIcon} alt="lock" />
                                        <span className="container-modal__firefox__text secondary">
                                            classroom.edu-cator.ru
                                        </span>
                                    </div>
                                </div>

                                <div className="container-modal__firefox__wrapper">
                                    <div>
                                        <p className="container-modal__firefox__text">
                                            Удалите запреты напротив камеры <br /> и микрофона
                                            (значок «Крестик»).
                                        </p>
                                        <p className="container-modal__firefox__text margin">
                                            Обновите страницу платформы.
                                        </p>
                                    </div>

                                    <div className="container-modal__firefox__settings">
                                        <span className="container-modal__firefox__text secondary">
                                            Разрешения
                                        </span>

                                        <div className="container-modal__firefox__settings__flex">
                                            <div className="container-modal__firefox__settings__microphone">
                                                <img
                                                    className="container-modal__firefox__settings__microphone__microphone"
                                                    src={microphoneSecondaryIcon}
                                                    alt="microphone"
                                                />
                                                <img
                                                    className="container-modal__firefox__settings__microphone__line"
                                                    src={lineIcon}
                                                    alt="line"
                                                />
                                            </div>
                                            <span>Использовать микрофон</span>
                                            <p>
                                                Временно <br /> заблокированно
                                            </p>
                                            <img src={closeIcon} alt="close" />
                                        </div>

                                        <div className="container-modal__firefox__settings__flex">
                                            <div className="container-modal__firefox__settings__microphone">
                                                <img
                                                    className="container-modal__firefox__settings__microphone__camera"
                                                    src={cameraSecondaryIcon}
                                                    alt="microphone"
                                                />
                                                <img
                                                    className="container-modal__firefox__settings__microphone__line"
                                                    src={lineIcon}
                                                    alt="line"
                                                />
                                            </div>
                                            <span>Использовать камеру</span>
                                            <p>
                                                Временно <br /> заблокированно
                                            </p>
                                            <img src={closeIcon} alt="close" />
                                        </div>
                                    </div>
                                </div>

                                <div className="container-modal__firefox__wrapper margin">
                                    <p className="container-modal__firefox__text">
                                        Нажмите на значок «Камера» слева <br /> от адресной строки.
                                        Выберите <br />
                                        нужные камеру и микрофон и <br /> нажмите «Разрешаю».
                                    </p>

                                    <div className="container-modal__firefox__permissions">
                                        <img
                                            className="container-modal__firefox__permissions__camera"
                                            src={cameraSecondaryIcon}
                                            alt="camera"
                                        />

                                        <div className="container-modal__firefox__permissions__content">
                                            <span className="container-modal__firefox__permissions__content__subtitle">
                                                Вы разрешаете classroom.edu-cator.ru использовать вашу
                                                камеру и микрофон?
                                            </span>

                                            <div className="container-modal__firefox__permissions__content__flex">
                                                <span>Доступ к камере:</span>
                                                <div>
                                                    <span>Ваша камера</span>
                                                    <img src={arrowGreenIcon} alt="arrow" />
                                                </div>
                                            </div>

                                            <div className="container-modal__firefox__permissions__content__flex">
                                                <span>Доступ к микрофону:</span>
                                                <div>
                                                    <span>Ваш микрофон</span>
                                                    <img src={arrowGreenIcon} alt="arrow" />
                                                </div>
                                            </div>

                                            <div className="container-modal__firefox__permissions__content__buttons">
                                                <div className="container-modal__firefox__permissions__content__button">
                                                    Разрешено
                                                </div>

                                                <div className="container-modal__firefox__permissions__content__button">
                                                    Не разрешено
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {activeTab === OPERA && (
                            <div className="container-modal__opera">
                                <div className="container-modal__opera__wrapper">
                                    <p className="container-modal__opera__text margin">
                                        Нажмите на значок «Камера» <br /> слева от адресной строки.
                                    </p>

                                    <div className="container-modal__opera__input">
                                        <img
                                            className="container-modal__opera__input__camera"
                                            src={cameraSecondaryIcon}
                                            alt="camera"
                                        />
                                        <img src={lockIcon} alt="lock" />
                                        <div className="container-modal__opera__input__line" />
                                        <span className="container-modal__opera__text secondary">
                                            classroom.edu-cator.ru
                                        </span>
                                    </div>
                                </div>

                                <div className="container-modal__opera__wrapper">
                                    <div>
                                        <p className="container-modal__opera__text margin">
                                            Очистите настройку и обновите <br /> страницу.
                                        </p>
                                    </div>

                                    <div className="container-modal__opera__permissions">
                                        <img
                                            className="container-modal__opera__permissions__camera"
                                            src={cameraSecondaryIcon}
                                            alt="camera"
                                        />

                                        <div className="container-modal__opera__permissions__content">
                                            <span className="container-modal__opera__permissions__content__subtitle">
                                                Доступ к камере и микрофону запрещен
                                            </span>

                                            <span className="container-modal__opera__permissions__content__text">
                                                classroom.edu-cator.ru
                                            </span>

                                            <span className="container-modal__opera__permissions__content__text">
                                                Не получено разрешение на доступ к камере или
                                                микрофону.
                                            </span>

                                            <span className="container-modal__opera__permissions__content__text">
                                                Для разрешения доступа и выбора входных устройств
                                                нажмите кнопку ниже. Страница будет перезагружена.
                                            </span>

                                            <div className="container-modal__opera__permissions__content__button">
                                                Очистить настройку и обновить
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="container-modal__opera__wrapper">
                                    <p className="container-modal__opera__text margin">
                                        Во всплывающем окне <br /> настройки доступа к камере и{' '}
                                        <br />
                                        микрофону нажмите кнопку <br /> «Разрешить».
                                    </p>

                                    <div className="container-modal__opera__settings">
                                        <span className="container-modal__opera__settings__subtitle">
                                            Запрос доступа к камере и микрофону
                                        </span>
                                        <span className="container-modal__opera__settings__text">
                                            Сайту classroom.edu-cator.ru требуется доступ к вашим камере{' '}
                                            <br />и микрофону. Разрешить?
                                        </span>

                                        <div className="container-modal__opera__settings__flex">
                                            <img
                                                className="container-modal__opera__settings__camera"
                                                src={cameraSecondaryIcon}
                                                alt="camera"
                                            />
                                            <div>
                                                <span>Ваша камера</span>
                                                <img src={arrowGreenIcon} alt="arrow" />
                                            </div>
                                        </div>

                                        <div className="container-modal__opera__settings__flex">
                                            <img
                                                className="container-modal__opera__settings__microphone"
                                                src={microphoneSecondaryIcon}
                                                alt="microphone"
                                            />
                                            <div>
                                                <span>Ваш микрофон</span>
                                                <img src={arrowGreenIcon} alt="arrow" />
                                            </div>
                                        </div>

                                        <div className="container-modal__opera__settings__buttons">
                                            <div className="container-modal__opera__settings__button">
                                                Разрешить
                                            </div>

                                            <div className="container-modal__opera__settings__button">
                                                Отклонить
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {activeTab === EDGE && (
                            <div className="container-modal__edge">
                                <div className="container-modal__edge__wrapper">
                                    <p className="container-modal__edge__text">
                                        Откройте меню «Параметры» <br /> Windows. Выберите раздел{' '}
                                        <br />
                                        «Конфиденциальность».
                                    </p>

                                    <div className="container-modal__edge__confidentiality">
                                        <img
                                            className="container-modal__edge__confidentiality__icon"
                                            src={confidentialityLockIcon}
                                            alt="camera"
                                        />

                                        <span className="container-modal__edge__subtitle margin">
                                            Конфиденциальность
                                        </span>

                                        <span className="container-modal__edge__text secondary">
                                            Расположение, камера
                                        </span>
                                    </div>
                                </div>

                                <div className="container-modal__edge__wrapper">
                                    <p className="container-modal__edge__text">
                                        Выберите пункт «Микрофон» и настройте <br /> разрешение.
                                        При необходимости <br /> настройте разрешение отдельно для{' '}
                                        <br />
                                        браузера в списке программ ниже.
                                    </p>

                                    <div className="container-modal__edge__permissions">
                                        <div className="container-modal__edge__permissions__flex">
                                            <span className="container-modal__edge__subtitle">
                                                Микрофон
                                            </span>

                                            <span className="container-modal__edge__text secondary margin">
                                                Разрешить приложениям использовать микрофон
                                            </span>
                                        </div>

                                        <div className="container-modal__edge__permissions__wrapper">
                                            <div className="container-modal__edge__permissions__switcher">
                                                <div className="container-modal__edge__permissions__switcher__ball" />
                                            </div>

                                            <span className="container-modal__edge__text secondary">
                                                Вкл.
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className="container-modal__edge__wrapper">
                                    <p className="container-modal__edge__text">
                                        Выберите пункт «Камера»и настройте <br /> разрешение. При
                                        необходимости <br /> настройте разрешение отдельно для{' '}
                                        <br />
                                        браузера в списке программ ниже.
                                    </p>

                                    <div className="container-modal__edge__permissions">
                                        <div className="container-modal__edge__permissions__flex">
                                            <span className="container-modal__edge__subtitle">
                                                Камера
                                            </span>

                                            <span className="container-modal__edge__text secondary margin">
                                                Разрешить приложениям использовать <br />{' '}
                                                оборудование камеры
                                            </span>
                                        </div>

                                        <div className="container-modal__edge__permissions__wrapper">
                                            <div className="container-modal__edge__permissions__switcher">
                                                <div className="container-modal__edge__permissions__switcher__ball" />
                                            </div>

                                            <span className="container-modal__edge__text secondary">
                                                Вкл.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="container-modal__buttons">
                            <Button type="tertiary" onClick={closeModal}>
                                {service ?? 'Начать занятие'}
                            </Button>

                            <button className="container-modal__buttons__item">
                                Ничего не выходит, мне нужна техническая поддержка. <br />
                                Позвоните нам -{' '}
                                <a href="tel: +7 800 775 59 76"> +7 800 775 59 76 </a>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ModalMediaResolution
