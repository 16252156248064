import {
    SET_CHAT_CANVAS_RECORDER,
    SET_CHAT_ID,
    SET_CHAT_ORDER_ID,
    SET_CHAT_TIMINGS,
    SET_CHAT_TOKEN,
    SET_CHAT_VIDEO,
    SET_CHAT_VIDEO_RECORDER,
    SET_IS_ACTIVE_CHAT_MESSENGER,
    SET_IS_CHAT_ACTIVE,
    SET_IS_CHAT_COMPLETED,
    SET_IS_VISIBLE_CHAT_MENU
} from '../constants/chats/actionTypes'
import { SET_CHAT_STREAM_TYPE } from '../constants/video/actionTypes' // eslint-disable-line

const initialState = {
    chatId: null,
    chatTimings: null,
    isActiveChat: true,
    isActiveChatMessenger: false,
    isVisibleChatMenu: false,
    isChatCompleted: false,
    chatToken: null,
    chatVideo: null,
    chatVideoRecorder: null,
    chatCanvasRecorder: null
}
export const chats = (state = initialState, action) => {
    switch (action.type) {
        case SET_CHAT_ID:
            return {
                ...state,
                chatId: action.payload
            }
        case SET_IS_CHAT_ACTIVE:
            return {
                ...state,
                isActiveChat: action.payload
            }
        case SET_CHAT_TIMINGS:
            return {
                ...state,
                chatTimings: action.payload
            }

        case SET_CHAT_TOKEN:
            return {
                ...state,
                chatToken: action.payload
            }
        case SET_CHAT_ORDER_ID:
            return {
                ...state,
                chatToken: action.payload
            }

        case SET_IS_VISIBLE_CHAT_MENU:
            return {
                ...state,
                isVisibleChatMenu: action.payload
            }
        case SET_IS_CHAT_COMPLETED:
            return {
                ...state,
                isChatCompleted: action.payload
            }

        case SET_IS_ACTIVE_CHAT_MESSENGER:
            return {
                ...state,
                isActiveChatMessenger: action.payload
            }
        case SET_CHAT_VIDEO:
            return {
                ...state,
                chatVideo: action.payload
            }
        case SET_CHAT_VIDEO_RECORDER:
            return {
                ...state,
                chatVideoRecorder: action.payload
            }
        case SET_CHAT_CANVAS_RECORDER:
            return {
                ...state,
                chatCanvasRecorder: action.payload
            }

        default:
            return state
    }
}
