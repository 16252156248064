import {
    SET_IS_READ_MESSAGE,
    SET_IS_RECEIVED_MESSAGE,
    SET_MESSAGES,
    SET_IMAGES_MESSAGE
} from '../constants/messages/actionTypes'

const initialState = {
    messages: [],
    isReceivedMessage: false,
    isReadMessage: false,
    images: []
}
export const messages = (state = initialState, action) => {
    switch (action.type) {
        case SET_MESSAGES:
            return {
                ...state,
                messages: action.payload
            }
        case SET_IS_RECEIVED_MESSAGE:
            return {
                ...state,
                isReceivedMessage: action.payload
            }
        case SET_IS_READ_MESSAGE:
            return {
                ...state,
                isReadMessage: action.payload
            }
        case SET_IMAGES_MESSAGE:
            return {
                ...state,
                images: action.payload
            }

        default:
            return state
    }
}
