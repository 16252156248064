import React from 'react'

import Header from '../../components/Header'

import burgerMenu from '../../assets/icons/burger-menu.svg'
import chatRecordButtonLarge from '../../assets/icons/chat-record-button-large.svg'
import lessonRecordButton from '../../assets/icons/lesson-record-button.svg'

import './LessonRecord.scss'

function LessonRecord() {
    return (
        <div className="lesson-record">
            <Header
                menuButton={
                    <button className="header__profile-burger completed-lesson-header-burger">
                        <img src={burgerMenu} alt="burger" />
                    </button>
                }
                className="completed-lesson-header header"
            />
            <div className="lesson-record__title">
                <h1>Запись занятия</h1>
            </div>
            <div className="lesson-record__content">
                <div className="lesson-record__content-video">
                    <video src="" />
                </div>
                <div className="lesson-record__content-record-buttons">
                    <div className="lesson-record__content-record-buttons-chat">
                        <div>
                            <img src={chatRecordButtonLarge} alt="кнопка записи чата" />
                        </div>
                        <h5>Запись чата</h5>
                    </div>
                    <div className="lesson-record__content-record-buttons-lesson">
                        <div>
                            <img src={lessonRecordButton} alt="кнопка записи чата" />
                        </div>
                        <h5>Запись доски</h5>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LessonRecord
