import {
    SET_VIDEO_PERMISSION,
    SET_AUDIO_PERMISSION,
    SET_MODAL_PERMISSION,
    SET_MODAL_SERVICE_PERMISSION
} from '../constants/mediaDevice/actionType'

export const setVideo = (payload) => ({
    type: SET_VIDEO_PERMISSION,
    payload
})
export const setAudio = (payload) => ({
    type: SET_AUDIO_PERMISSION,
    payload
})
export const setModal = (payload) => ({
    type: SET_MODAL_PERMISSION,
    payload
})
export const setModalService = (payload) => ({
    type: SET_MODAL_SERVICE_PERMISSION,
    payload
})
