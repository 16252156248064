import { combineReducers } from 'redux'

import { chats } from './chats'
import { messages } from './messages'
import { users } from './users'
import { video } from './video'
import { order } from './order'
import { mediaDevicePermission } from './mediaDevice'
import { clientError } from './clientError'

export const rootReducer = combineReducers({
    chats,
    messages,
    users,
    video,
    order,
    mediaDevicePermission,
    clientError
})
