import {
    SET_IS_READ_MESSAGE,
    SET_IS_RECEIVED_MESSAGE,
    SET_MESSAGES,
    SET_IMAGES_MESSAGE
} from '../constants/messages/actionTypes'

export const setMessages = (payload) => ({
    type: SET_MESSAGES,
    payload
})
export const setIsReceivedMessage = (payload) => ({
    type: SET_IS_RECEIVED_MESSAGE,
    payload
})
export const setIsReadMessage = (payload) => ({
    type: SET_IS_READ_MESSAGE,
    payload
})
export const setImagesMessage = (payload) => ({
    type: SET_IMAGES_MESSAGE,
    payload
})
