import axios from 'axios'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import useRoomContext from '../../context/roomContext'
import { setImagesMessage, setIsReceivedMessage, setMessages } from '../../store/actions/messages'
import { selectImagesMessage, selectMessages } from '../../store/selectors/messages'

const MessangerService = () => {
    const dispatch = useDispatch()
    const { socket } = useRoomContext()
    const { token } = useParams()

    const messages = useSelector(selectMessages)
    const images = useSelector(selectImagesMessage)

    const downloadImage = async (message, image) => {
        const config = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/public/chat/media/download`,
            data: {
                id: message.id,
                key: image.key
            },
            headers: {
                'x-access-token': token
            },
            responseType: 'arraybuffer'
        }
        const resp = await axios(config)
        if (resp?.status === 200) {
            if (
                ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'].includes(
                    image.key.split('.').at(-1)
                )
            ) {
                const url = window.URL.createObjectURL(new Blob([resp.data]))
                const replaceText = `messages-media/user/${message.userId}/${
                    image.key?.split('/')[3].split('-')[0] ?? ''
                }`
                return {
                    id: image.key,
                    msgId: message.id,
                    url: url,
                    name: image.key.replace(replaceText, '').slice(1)
                }
            } else if (['wav', 'mp3', 'ogg'].includes(image.key.split('.').at(-1))) {
                const audio = window.URL.createObjectURL(new Blob([resp.data]))
                const replaceText = `messages-media/user/${message.userId}/${
                    image.key?.split('/')[3].split('-')[0] ?? ''
                }`
                return {
                    id: image.key,
                    msgId: message.id,
                    audio: audio,
                    name: image.key.replace(replaceText, '').slice(1)
                }
            } else {
                const buffer = Buffer.from(resp.data)
                const base64String = buffer.toString('base64')
                return {
                    id: image.key,
                    msgId: message.id,
                    src: `data:image/jpg;base64,${base64String}`
                }
            }
        }
    }

    const setMessageInChat = async (msg) => {
        if (msg?.media?.length) {
            const promises = []

            for (const image of msg.media) {
                if (images.find((e) => e.id === image.key && e.msgId === msg.id)) {
                    continue
                }
                promises.push(downloadImage(msg, image))
            }
            dispatch(
                setImagesMessage([...images, ...(await Promise.all(promises)).filter((e) => e)])
            )
        }
    }

    useEffect(() => {
        socket.on('server-messages-set', async (message) => {
            dispatch(setIsReceivedMessage(true))
            dispatch(setMessages([...messages, message]))
        })
    }, [socket, messages]) // eslint-disable-line

    useEffect(() => {
        if (messages.length) {
            setMessageInChat(messages[messages.length - 1])
        }
    }, [messages]) // eslint-disable-line

    return null
}

export default MessangerService
