import {
    SET_VIDEO_PERMISSION,
    SET_AUDIO_PERMISSION,
    SET_MODAL_PERMISSION,
    SET_MODAL_SERVICE_PERMISSION
} from '../constants/mediaDevice/actionType'

const initialState = {
    video: null,
    audio: null,
    modal: null,
    service: null
}
export const mediaDevicePermission = (state = initialState, action) => {
    switch (action.type) {
        case SET_VIDEO_PERMISSION:
            return {
                ...state,
                video: action.payload
            }
        case SET_AUDIO_PERMISSION:
            return {
                ...state,
                audio: action.payload
            }
        case SET_MODAL_PERMISSION:
            return {
                ...state,
                modal: action.payload
            }
        case SET_MODAL_SERVICE_PERMISSION:
            return {
                ...state,
                service: action.payload
            }

        default:
            return state
    }
}
