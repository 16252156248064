import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import classNames from 'classnames'
import moment from 'moment'
import axios from 'axios'

import useRoomContext from '../../context/roomContext'

import { selectChatId } from '../../store/selectors/chats'
import { selectMessages, selectImagesMessage } from '../../store/selectors/messages'
import { setIsReadMessage, setIsReceivedMessage, setMessages } from '../../store/actions/messages'
import { selectOtherUser, selectUser } from '../../store/selectors/users'

import readMessage from '../../assets/icons/read-message.svg'
import close from '../../assets/icons/close.svg'
import gallery from '../../assets/icons/gallery.svg'
import sendMessage from '../../assets/icons/send-message.svg'
import loading from '../../assets/icons/loading.svg'

import './Messenger.scss'

import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'

function Messanger() {
    const dispatch = useDispatch()
    const { socket } = useRoomContext()

    const [messageText, setMessageText] = useState('')
    const [media, setMedia] = useState([])
    const [messageFiles, setMessageFiles] = useState([])
    const [sendLoader, setSendLoader] = useState(false)

    const chatId = useSelector(selectChatId)
    const messages = useSelector(selectMessages)
    const user = useSelector(selectUser)
    const otherUser = useSelector(selectOtherUser)
    const images = useSelector(selectImagesMessage)
    const { token } = useParams()

    const [photoIndex, setPhotoIndex] = useState(0)
    const [viewerIsOpen, setViewerIsOpen] = useState(false)

    const sendImages = async () => {
        for (const img of messageFiles) {
            try {
                const config = {
                    method: 'post',
                    url: `${process.env.REACT_APP_API_URL}/public/chat/media`,
                    data: img.formData,
                    headers: {
                        'x-access-token': token,
                        'Content-Type': 'multipart/form-data'
                    }
                }

                const { data } = await axios(config)

                if (data?.success) {
                    media.push(data.result[0])
                }
            } catch (e) {}
        }
    }

    const onMessageSend = async (text) => {
        if (!text && messageFiles.length === 0) {
            return
        }
        setSendLoader(true)
        await sendImages()
        socket.emit('public-messages-set', { text, chatId, media }, ({ result }) => {
            const message = result
            dispatch(setIsReadMessage(false))
            dispatch(setMessages([...messages, message]))
            setMessageFiles([])
            setMedia([])
            setSendLoader(false)
        })
        setMessageText('')
    }

    const getBase64 = (event) => {
        if (event.target.files[0]) {
            for (const file of event.target.files) {
                const formData = new FormData()
                formData.append('files', file)
                const reader = new FileReader()
                if (
                    [
                        'application/pdf',
                        'application/msword',
                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        'application/vnd.ms-excel',
                        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                        'application/vnd.ms-powerpoint',
                        'audio/mpeg',
                        'audio/wav',
                        'audio/ogg'
                    ].includes(file.type)
                ) {
                    messageFiles.push({ id: messageFiles.length, name: file.name, formData })
                } else {
                    reader.onload = (e) => {
                        messageFiles.push({
                            id: messageFiles.length,
                            src: e.target.result,
                            formData
                        })
                    }
                }
                reader.readAsDataURL(file)
            }
        }
    }

    const openImage = (image) => {
        const imageIndex = images.indexOf(image)
        setPhotoIndex(imageIndex)
        setViewerIsOpen(true)
    }

    return (
        <div className="messenger" id="messenger">
            <div className="messenger__messages">
                {messages &&
                    messages.map((message, i) => (
                        <div
                            key={i}
                            className={classNames(
                                'messenger__message',
                                message && message.userId === user.id
                                    ? 'messenger__message_outgoing'
                                    : 'messenger__message_incoming'
                            )}
                        >
                            {message && message.userId !== user.id && (
                                <>
                                    <p className="messenger__message-time">
                                        {moment(message.createdAt).format('HH:mm')}
                                    </p>
                                    <span className="messenger__message-teacher">
                                        {`${otherUser.role.name}: ${otherUser.attributes.firstName} ${otherUser.attributes.lastName}`}
                                    </span>
                                </>
                            )}
                            <div style={{ display: 'flex' }}>
                                {message && message.userId === user.id && (
                                    <img
                                        src={readMessage}
                                        alt="read"
                                        style={{ marginRight: '10px', alignSelf: 'flex-end' }}
                                    />
                                )}
                                <div className="messenger__message-text">
                                    {message && message.text && (
                                        <p onClick={() => dispatch(setIsReceivedMessage(true))}>
                                            {message.text}
                                        </p>
                                    )}
                                    {message &&
                                    message.media.length &&
                                    images.find((e) => e.msgId === message.id) ? (
                                        <div
                                            className="messenger__message-images"
                                            onClick={() => dispatch(setIsReceivedMessage(true))}
                                        >
                                            {images
                                                .filter((e) => e.msgId === message.id)
                                                .map((image, i) =>
                                                    image.src ? (
                                                        <div
                                                            key={i}
                                                            className={'messenger__message-image'}
                                                            onClick={() => openImage(image)}
                                                        >
                                                            <img src={image.src} alt="pictures" />
                                                        </div>
                                                    ) : image.audio ? (
                                                        <audio
                                                            key={i}
                                                            src={image.audio}
                                                            controls
                                                        />
                                                    ) : image.url ? (
                                                        <a
                                                            key={i}
                                                            className={'messenger__message-pdf'}
                                                            href={image.url}
                                                            download={image.name}
                                                        >
                                                            Скачать {image.name}
                                                        </a>
                                                    ) : null
                                                )}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
            {messageFiles ? (
                <div className="messenger__media">
                    {messageFiles &&
                        messageFiles.map((file, i) => (
                            <div key={i} className="messenger__media__item">
                                {file.name ? (
                                    <div className="messenger__media__item__pdf">
                                        {file.name.length > 40
                                            ? file.name.slice(0, 40) + '...'
                                            : file.name}
                                    </div>
                                ) : (
                                    <img
                                        src={file.src}
                                        alt=""
                                        className="messenger__media__item__image"
                                    />
                                )}
                                <div
                                    className="messenger__media__item__image__delete"
                                    onClick={() =>
                                        setMessageFiles(
                                            messageFiles.filter((e) => e.id !== file.id)
                                        )
                                    }
                                >
                                    <img src={close} alt="" />
                                </div>
                            </div>
                        ))}
                </div>
            ) : null}
            <div className="messenger__form">
                <div
                    className="messenger__form-download"
                    style={{ flex: 1, display: 'flex', justifyContent: 'center' }}
                >
                    <label>
                        <input
                            type="file"
                            style={{
                                display: 'none'
                            }}
                            multiple
                            accept="image/jpg, image/jpeg, image/png, application/pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .wav, .mp3, .ogg"
                            onChange={(e) => getBase64(e)}
                        />

                        <img src={gallery} style={{ width: 40, height: 40 }} alt="gallery" />
                    </label>
                </div>
                {/* <div className="messenger__form-input">
                    <input
                        type="text"
                        placeholder="Aa"
                        value={messageText}
                        onChange={(e) => setMessageText(e.target.value)}
                        onKeyDown={(e) => e.key === 'Enter' && onMessageSend(messageText)}
                    />
                </div> */}
                {!sendLoader ? (
                    <div
                        className="messenger__form-send"
                        onClick={() => onMessageSend(messageText)}
                    >
                        <img src={sendMessage} alt="send icon" />
                    </div>
                ) : (
                    <div className="messenger__form-send">
                        <img src={loading} alt="loading icon" />
                    </div>
                )}
            </div>
            {viewerIsOpen && (
                <Lightbox
                    mainSrc={images.map((image) => image.src)[photoIndex]}
                    nextSrc={
                        photoIndex + 1 === images.length
                            ? ''
                            : images.map((image) => image.src)[(photoIndex + 1) % images.length]
                    }
                    prevSrc={
                        photoIndex === 0
                            ? ''
                            : images.map((image) => image.src)[
                                  (photoIndex + images.length - 1) % images.length
                              ]
                    }
                    onCloseRequest={() => setViewerIsOpen(false)}
                    onMovePrevRequest={() =>
                        setPhotoIndex((photoIndex + images.length - 1) % images.length)
                    }
                    onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
                />
            )}
        </div>
    )
}

export default Messanger
