import React, { memo } from 'react'
import classNames from 'classnames'

import { useSelector, useDispatch } from 'react-redux'

import { selectIsVisibleChatMenu } from '../../store/selectors/chats'
import { selectUser, selectUsers } from '../../store/selectors/users'
import { selectCanvasToolbarState } from '../../store/selectors/video'

import { setCanvasToolbarState } from '../../store/actions/video'

import avatar from '../../assets/icons/avatar.svg'
import logo from '../../assets/icons/logo.svg'
import ButtonSettingsSvg from '../../assets/icons/button-settings.svg'

import './Header.scss'

function Header({ menuButton, className, toolbar, timer, hours, minutes, seconds, settings }) {
    const dispatch = useDispatch()

    const user = useSelector(selectUser)
    const users = useSelector(selectUsers)
    const isVisibleChatMenu = useSelector(selectIsVisibleChatMenu)
    const showCanvasToolbar = useSelector(selectCanvasToolbarState)

    return (
        <header
            className={classNames(
                className,
                isVisibleChatMenu ? 'active' : '',
                settings ? 'settings' : ''
            )}
            style={{ padding: '0 20px', paddingTop: '5px' }}
            id="header"
        >
            <div className="header__logo">
                <img src={logo} alt="логотип" />
            </div>
            {toolbar && <div className="header__toolbar">{toolbar}</div>}
            {timer && (
                <div
                    className={classNames(
                        'header__timer',
                        !hours && minutes <= 5 ? 'header__timer_red' : 'header__timer_gray'
                    )}
                >
                    <p>
                        {`${hours}:${minutes}`}
                        <span>:{seconds < 10 ? `0${seconds}` : `${seconds}`}</span>
                    </p>
                </div>
            )}
            <div className="header__profile">
                <div className="header__profile-wrap">
                    {user.attributes && (
                        <p>{`${user.attributes.firstName} ${user.attributes.lastName}`}</p>
                    )}
                    <div className="header__profile-avatar">
                        <img src={user.avatar || avatar} alt="avatar" />
                    </div>
                    <div className="header__profile-buttons">{menuButton}</div>
                </div>
                <div
                    className={classNames(
                        'header__profile-content',
                        isVisibleChatMenu ? 'active' : ''
                    )}
                >
                    {users?.map((user) => {
                        return (
                            <div className="header__profile-card" key={user.id}>
                                <p>{`${user.attributes.firstName} ${user.attributes.lastName}`}</p>
                                <div className="header__profile-card-avatar">
                                    <img src={user.avatar || avatar} alt="avatar" />
                                </div>
                                <div className="header__profile-card-radio">
                                    <label htmlFor={user.id} className="radio">
                                        <input type="radio" name="radio" id={user.id} />
                                        <div className="radio-content"></div>
                                    </label>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            {settings && (
                <div
                    className="header__settings"
                    onClick={() => dispatch(setCanvasToolbarState(!showCanvasToolbar))}
                >
                    <img src={ButtonSettingsSvg} alt="button-settings" />
                </div>
            )}
        </header>
    )
}
export default memo(Header)
