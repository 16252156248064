import {
    SET_CLIENT_ERROR,
    SET_RTC_CONNECTION_TIME,
    SET_RTC_DISCONNECTION_TIME,
    SET_ON_GO_BACK_TO_PUBLIC
} from '../constants/clientError/actionType'

const initialState = {
    error: null,
    rtcConnectionTime: null,
    rtcDisconnectionTime: null,
    onGoBackToPublic: false
}
export const clientError = (state = initialState, action) => {
    switch (action.type) {
        case SET_CLIENT_ERROR:
            return {
                ...state,
                error: action.payload
            }

        case SET_RTC_CONNECTION_TIME:
            return {
                ...state,
                rtcConnectionTime: action.payload
            }

        case SET_RTC_DISCONNECTION_TIME:
            return {
                ...state,
                rtcDisconnectionTime: action.payload
            }

        case SET_ON_GO_BACK_TO_PUBLIC:
            return {
                ...state,
                onGoBackToPublic: action.payload
            }

        default:
            return state
    }
}
