import React from 'react'
import classNames from 'classnames'
import './Button.scss'

function Button({ children, onClick, disabled, type }) {
    return (
        <button onClick={onClick} disabled={disabled} className={classNames('button', type)}>
            {children}
        </button>
    )
}
export default Button
