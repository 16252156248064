import { SET_USER, SET_USERS } from '../constants/users/actionType'

export const setUser = (payload) => ({
    type: SET_USER,
    payload
})
export const setUsers = (payload) => ({
    type: SET_USERS,
    payload
})

export const UPDATE_USER = 'UPDATE_USER'
export const updateUser = (user) => ({ type: UPDATE_USER, payload: user })
