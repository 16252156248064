import React from 'react'

import closeIcon from '../../assets/icons/close-modal.svg'

import './Modal.scss'

function Modal({ title, content, close }) {
    return (
        <div className="modal__wrapper">
            <div className="modal__bg" />

            {close && (
                <div className="modal__close" onClick={close}>
                    <img src={closeIcon} alt="close icon" />
                </div>
            )}

            <div className="modal__container">
                <div className="separator" />

                <div className="modal__window">
                    <h2 className="modal__title">{title}</h2>
                    {content}
                </div>

                <div className="separator" />
            </div>
        </div>
    )
}
export default Modal
