export const roomError = {
    cookiesPolicy: 'cookies-policy-error',
    unauthorized: "You have no access to this order's chat",
    invalidOrder: 'Wrong order status',
    unknown: 'unknown'
}

export const errorTextByMessage = {
    [roomError.invalidOrder]: 'Ошибка с подключением в комнату, неверный статус заказа!',
    [roomError.unauthorized]:
        'Ошибка с подключением в комнату, у вас нет доступа к чату этого заказа!',
    [roomError.unknown]: 'Ошибка с подключением в комнату!'
}
