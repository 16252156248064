import axios from 'axios'
import moment from 'moment'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import useRoomContext from '../../context/roomContext'
import { setChatId, setChatTimings } from '../../store/actions/chats'
import {
    setClientError,
    setRTCConnectionTime,
    setRTCDisconnectionTime
} from '../../store/actions/clientError'
import { setImagesMessage, setMessages } from '../../store/actions/messages'
import { setOrder } from '../../store/actions/order'
import { setUser, setUsers, updateUser } from '../../store/actions/users'
import {
    selectRTCConnectionTime,
    selectRTCDisconnectionTime,
    selectOnGoBackToPublic
} from '../../store/selectors/clientError'
import { selectOrder } from '../../store/selectors/order'
import { errorTextByMessage, roomError } from './constants'

export const WorkspaceService = ({
    setChatDetails,
    setBbbUrl,
    setTeacherAvtar,
    setError,
    setInfoMessagesInChat,
    setOnStart
}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { socket } = useRoomContext()
    const { token, orderId } = useParams()

    const order = useSelector(selectOrder)
    const rtcConnectionTime = useSelector(selectRTCConnectionTime)
    const rtcDisconnectionTime = useSelector(selectRTCDisconnectionTime)
    const onGoBackToPublic = useSelector(selectOnGoBackToPublic)

    const downloadImage = async (message, image) => {
        const config = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/public/chat/media/download`,
            data: {
                id: message.id,
                key: image.key
            },
            headers: {
                'x-access-token': token
            },
            responseType: 'arraybuffer'
        }
        const resp = await axios(config)
        if (resp?.status === 200) {
            if (
                ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'].includes(
                    image.key.split('.')[1]
                )
            ) {
                const url = window.URL.createObjectURL(new Blob([resp.data]))
                const replaceText = `messages-media/user/${message.userId}/${
                    image.key?.split('/')[3].split('-')[0] ?? ''
                }`
                return {
                    id: image.key,
                    msgId: message.id,
                    url: url,
                    name: image.key.replace(replaceText, '').slice(1)
                }
            } else if (['wav', 'mp3', 'ogg'].includes(image.key.split('.')[1])) {
                const audio = window.URL.createObjectURL(new Blob([resp.data]))
                const replaceText = `messages-media/user/${message.userId}/${
                    image.key?.split('/')[3].split('-')[0] ?? ''
                }`
                return {
                    id: image.key,
                    msgId: message.id,
                    audio: audio,
                    name: image.key.replace(replaceText, '').slice(1)
                }
            } else {
                const buffer = Buffer.from('ArrayBuffer', resp.datartcDisconnectionTime)
                const base64String = buffer.toString('base64')
                return {
                    id: image.key,
                    msgId: message.id,
                    src: `data:image/jpg;base64,${base64String}`
                }
            }
        }
    }

    useEffect(() => {
        const connectionTimeout = setTimeout(() => {
            if (!onGoBackToPublic && !socket.connected) {
                setError(roomError.unknown)
                dispatch(
                    setClientError({
                        title: 'Ошибка',
                        text: `Ошибка подключения к серверу, не удаётся установить соединение!`,
                        type: 'RTC',
                        canClose: true
                    })
                )
            }
        }, 20000)

        socket.on('connect_error', (error) => {
            clearTimeout(connectionTimeout)

            if (error.message === 'Unauthorized!') {
                setError(roomError.unauthorized) // 🚧 must be uncommented
                dispatch(
                    setClientError({
                        title: 'Ошибка',
                        text: `Ошибка подключения к серверу, ${
                            !token ? 'отсутствует токен' : 'невалидный токен'
                        }, WebSocket закрывается до установления соединения!`,
                        type: 'RTC',
                        canClose: true
                    })
                )
            }
        })
    }, [socket, token]) // eslint-disable-line

    useEffect(() => {
        socket.emit('public-user-get', {}, ({ result }) => {
            console.log('user: ', result)
            dispatch(setUser(result))

            if (result?.attributes?.avatar) {
                const config = {
                    method: 'get',
                    url: `${process.env.REACT_APP_API_URL}/public/user/avatar/${result.id}`,
                    headers: {
                        'x-access-token': token
                    },
                    responseType: 'arraybuffer'
                }

                axios(config)
                    .then((resp) => {
                        if (resp && resp.status === 200) {
                            const buffer = Buffer.from(resp.data)
                            const base64String = buffer.toString('base64')
                            const avatar = `data:image/jpg;base64,${base64String}`
                            // result.avatar = `data:image/jpg;base64,${base64String}`
                            dispatch(setUser({ ...result, avatar }))
                        }
                    })
                    .catch((e) => {
                        console.log(e)
                    })
            }

            if (result) {
                const config = {
                    method: 'post',
                    url: `${process.env.REACT_APP_API_URL}/public/orders/list`,
                    data: {
                        filter: {
                            id: orderId
                        }
                    },
                    headers: {
                        'x-access-token': token
                    }
                }

                axios(config)
                    .then(({ data }) => {
                        const order = data.result[0]

                        if (order) {
                            dispatch(setOrder(order))
                        }

                        console.log('order: ', order)
                    })
                    .catch((e) => {
                        console.log(e)
                    })
            }
        })
    }, [socket, orderId, token]) // eslint-disable-line

    useEffect(() => {
        if (order?.teacher?.attributes?.avatar) {
            try {
                const config = {
                    method: 'get',
                    url: `${process.env.REACT_APP_API_URL}/public/user/avatar/${order?.teacherId}`,
                    responseType: 'arraybuffer',
                    headers: {
                        'x-access-token': token
                    }
                }

                axios(config).then(({ data }) => {
                    const buffer = Buffer.from(data)
                    const base64String = buffer.toString('base64')
                    setTeacherAvtar(`data:image/jpg;base64,${base64String}`)
                })
            } catch (e) {
                console.log(e)
            }
        }
    }, []) // eslint-disable-line

    useEffect(() => {
        socket.on('connect', () => {
            console.log('%csocket connect: ', `color: ${socket ? 'green' : '#DC4C64'}`, socket)
            dispatch(setRTCConnectionTime(moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ')))

            socket.emit(
                'public-chats-enter',
                {
                    orderId,
                    token
                },
                async (res) => {
                    console.log('public-chats-enter: ', res)

                    if (!res.success && res.message === roomError.invalidOrder) {
                        navigate('completed')
                    } else if (!res.success) {
                        setError(errorTextByMessage[res.message] ? res.message : roomError.unknown)
                        dispatch(
                            setClientError({
                                title: 'Ошибка',
                                text:
                                    errorTextByMessage[res.message] ??
                                    errorTextByMessage[roomError.unknown],
                                canClose: true
                            })
                        )

                        return
                    }

                    const { result } = res
                    setBbbUrl(result.meetingUrl)
                    dispatch(setChatId(result.id))
                    for (const user of result.users) {
                        if (!user?.attributes?.avatar) continue

                        const config = {
                            method: 'get',
                            url: `${process.env.REACT_APP_API_URL}/public/user/avatar/${user.id}`,
                            responseType: 'arraybuffer',
                            headers: {
                                'x-access-token': token
                            }
                        }

                        axios(config)
                            .then(({ data }) => {
                                const buffer = Buffer.from(data)
                                const base64String = buffer.toString('base64')
                                const avatar = `data:image/jpg;base64,${base64String}`
                                dispatch(updateUser({ ...user, avatar }))
                            })
                            .catch((e) => {
                                console.log(e)
                            })
                    }
                    dispatch(setUsers(result.users))
                    dispatch(setMessages(result.messages))
                    setChatDetails(result)
                    if (result.timings?.active) {
                        dispatch(setChatTimings(result.timings))
                    }
                    const promises = []
                    for (const message of result.messages.filter((e) => e.media.length)) {
                        for (const image of message.media) {
                            promises.push(downloadImage(message, image))
                        }
                    }
                    dispatch(setImagesMessage((await Promise.all(promises)).filter((e) => e)))
                }
            )
        })

        socket.on('disconnect', () => {
            console.log('%csocket status disconnected', 'color: #DC4C64')
            dispatch(
                setClientError({
                    title: 'Ошибка',
                    text: 'Ошибка подключения к серверу, проверьте интернет соединение!',
                    type: 'RTC',
                    error: 'DISCONECTED'
                })
            )

            dispatch(setRTCDisconnectionTime(moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ')))
        })
    }, [socket, orderId, token]) // eslint-disable-line

    useEffect(() => {
        if (rtcConnectionTime && !rtcDisconnectionTime) {
            dispatch(setRTCConnectionTime(null))
        }

        if (rtcConnectionTime && rtcDisconnectionTime) {
            try {
                const config = {
                    method: 'post',
                    url: `${process.env.REACT_APP_API_URL}/public/notifications/lost-connection`,
                    headers: {
                        'x-access-token': token
                    },
                    data: {
                        lossTime: rtcDisconnectionTime,
                        reconnectionTime: rtcConnectionTime,
                        orderId: orderId
                    }
                }

                axios(config).then(({ data }) => {
                    console.log('lost connection notification: ', data)
                })
            } catch (e) {
                console.log('lost connection notification: ', e)
            }
        }
    }, [rtcConnectionTime, rtcDisconnectionTime]) // eslint-disable-line

    useEffect(() => {
        socket.on('server-chats-start', ({ timings }) => {
            dispatch(setChatTimings(timings))
            setInfoMessagesInChat(timings)
            setOnStart(true)
        })
    }, []) // eslint-disable-line

    useEffect(() => {
        socket.on('server-chats-break', () => {
            dispatch(setChatTimings(null))
        })
        socket.on('server-notifications-send', ({ type, data }) => {
            if (data) {
                const { statusId, orderId } = JSON.parse(data)
                if ([4, 5].includes(statusId) && type.id === 15) {
                    navigate(`/room/${orderId}/${token}/completed`)
                }
            }
        })
    }, [socket]) // eslint-disable-line

    return null
}
