import React from 'react'

import closeIcon from '../../assets/icons/close-notice.svg'
import notificationIcon from '../../assets/icons/notification.svg'

import './ClientNotice.scss'

function ClientNotice({ title, text, close }) {
    return (
        <div className="notice__wrapper">
            <img className="notice__icon" src={notificationIcon} alt="notification icon" />
            <div>
                <h2 className="notice__title">{title}</h2>
                <p className="notice__text">{text}</p>
            </div>
            {close && (
                <div className="notice__close" onClick={close}>
                    <img src={closeIcon} alt="close icon" />
                </div>
            )}
        </div>
    )
}
export default ClientNotice
