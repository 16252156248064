import React, { useRef, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Outlet } from 'react-router-dom'
import { io } from 'socket.io-client'
import { roomContext } from '../../context/roomContext'

function Room() {
    const { token } = useParams()

    /** @type {{ current: import('socket.io-client').Socket }} */
    const socketRef = useRef()
    const currSocketToken = useRef()

    if (!socketRef.current && token) {
        currSocketToken.current = token
        socketRef.current = io(process.env.REACT_APP_RTC_URL, {
            extraHeaders: { 'x-access-token': token }
        })
    }

    useEffect(() => {
        if (token === currSocketToken.current) return

        if (!token) {
            socketRef.current.disconnect()
            return
        }

        currSocketToken.current = token
        socketRef.current = io(process.env.REACT_APP_RTC_URL, {
            extraHeaders: { 'x-access-token': token }
        })
    }, [token])

    const context = { socket: socketRef.current }

    return (
        <roomContext.Provider value={context}>
            <Outlet />
        </roomContext.Provider>
    )
}

export default Room
