import { SET_ORDER } from '../constants/order/actionTypes'

const initialState = {
    order: null
}
export const order = (state = initialState, action) => {
    switch (action.type) {
        case SET_ORDER:
            return {
                ...state,
                order: action.payload
            }
        default:
            return state
    }
}
