import { createSelector } from 'reselect'

export const selectChatId = ({ chats }) => chats.chatId
export const selectIsActiveChatMessanger = ({ chats }) => chats.isActiveChatMessenger
export const selectIsVisibleChatMenu = ({ chats }) => chats.isVisibleChatMenu
export const selectIsChatCompleted = ({ chats }) => chats.isChatCompleted
export const selectChatTimings = ({ chats }) => chats.chatTimings
export const selectChatVideoRecorder = ({ chats }) => chats.chatVideoRecorder

export const selectChatDuration = createSelector(selectChatTimings, (chatTimings) =>
    chatTimings?.duration ? chatTimings?.duration : 0
)
export const selectChatStartTime = createSelector(selectChatTimings, (chatTimings) =>
    chatTimings?.startTime ? chatTimings?.startTime * 1000 : 0
)
export const selectOrderStartTime = createSelector(selectChatTimings, (chatTimings) =>
    chatTimings?.orderStart ? chatTimings?.orderStart * 1000 : 0
)
